import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Software/Linux/OpenHAB2/PrimaryBox';
import ForumBox from 'components/Software/Linux/OpenHAB2/ForumBox';
import NavButtons from 'components/Software/Linux/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "openHAB 2 on Debian",
  "path": "/Software/Linux/OpenHAB2/Deprecated/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='openHAB 2 on Debian' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Linux/OpenHAB2/Deprecated/' locationFR='/fr/Software/Linux/OpenHAB2/Deprecated/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux",
        "aria-label": "linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LINUX`}</h2>
    <h3 {...{
      "id": "openhab",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#openhab",
        "aria-label": "openhab permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHab`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-2-installation"
        }}>{`OpenHAB 2 Installation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-install-java-with-apt-on-debian-9"
            }}>{`How to install Java with Apt on Debian 9`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#installing-the-default-jrejdk"
                }}>{`Installing the Default JRE/JDK`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-configuration-though-paper-ui"
        }}>{`OpenHAB Configuration though Paper UI`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mqtt-binding"
            }}>{`MQTT Binding`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#openhab-cloud-connector"
            }}>{`OpenHAB Cloud Connector`}</a></li>
        </ul>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "openhab-2-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-2-installation",
        "aria-label": "openhab 2 installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 2 Installation`}</h2>
    <p>{`We are going to use the apt-get package manager to install the latest OpenHAB 2 snapshot on our Debian Linux server. For more installation options - different Linux versions, stable builds, etc. - check out the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/download/"
      }}>{`OpenHAB Download Section`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">{`We will start by adding the needed repository key:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` -qO - `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://bintray.com/user/downloadSubjectPublicKey?username=openhab'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` apt-key `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` -`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Add the HTTPS transport for APT:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` apt-transport-https`}</code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Add the repository:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'deb https://openhab.jfrog.io/openhab/openhab-linuxpkg unstable main'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tee`}</span>{` /etc/apt/sources.list.d/openhab2.list`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/e9beb/OpenHAB2_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSUlEQVQY0yXNSZKkIBQAUI/SUQ4giiDwGUWcM9XMyqr7X6ajozdv+7LtmB7nmuZxSMFFSxmRikrFwHBtOGhmDNe2ByutE8YK7aQEroyQus/ue3u9DxcsOBXnwQXpPdeGOceC50Pog+ukJBX6w2hOad7SkrRFgb8QybP7fXx+zuVI2oOyooeuZbjpMGV1+1+G6qYq8Vfd5KjOSVvipqhIgUiRvT/nz+/1vPf92tIa520KyYMD8OCiBQdcspY3XHZMdlx2uKlQXZY4R6TKznu/723Z0/qY0xLSOqZ1HJcodO+jAwfaqV4xAVyaXhnBRUeBUUExRdnr89ye8/5cxn2CbZq2cd7T49rWxzIkb4OJ82AcMPGvVaYfotFBCS1a1mTfv/f5Os7v87rSlIT2Zpz8vMYxuWE0YVBSc+Ok0r2EjouWUIRJWeGiwsVf6y1ZiJwx+G8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36a464cee51fdccfc7ba5f0f43b7121d/e4706/OpenHAB2_01.avif 230w", "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/d1af7/OpenHAB2_01.avif 460w", "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/b6582/OpenHAB2_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36a464cee51fdccfc7ba5f0f43b7121d/a0b58/OpenHAB2_01.webp 230w", "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/bc10c/OpenHAB2_01.webp 460w", "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/87ca7/OpenHAB2_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36a464cee51fdccfc7ba5f0f43b7121d/81c8e/OpenHAB2_01.png 230w", "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/08a84/OpenHAB2_01.png 460w", "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/e9beb/OpenHAB2_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36a464cee51fdccfc7ba5f0f43b7121d/e9beb/OpenHAB2_01.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Update the package lists and install the openHAB distribution package:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` openhab2`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/698edbffb9d55d81474456ecd7981524/e9beb/OpenHAB2_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC4UlEQVQozyWSWbKjOBRE2Ua90WZGIMRoEEgChITEaPPcw/530u2qiIwT9yfz48Q1RsUnzYXiUnEx8Z4zNctJCzrQQfZc9noRtGtxW3UDiZMoQsAD7vvXr2WbjWUV6yYnzfU6cdmTvn2c+jyX4673jd/v07GNy8LF1M264yMmrIIouNpf93MzHufyOOd1n/Qq+dT3ovt5rj/P9bir45D//Hto3dGuaumNczxwPApS4SxE/s/zMITmehFq5oy33Ug7wUY1DLLHtO5GJhQvcYGyOEQgzqAfuqZzcX374/ttOxZDb2o79POvjQ5tw2o+9TAJA+j7kfcnXuDYnmm55m9eLffqAMfyzMffd2PZ1bxN613LeaScZFWKcuiFrh+5vyf81x2+VgD0UBqhNIyyyHQv674Yw0jVIthAxDyOemhYLdXQjZTQkjA88Ba35Q0XLau5oFywCudZgb4u7y/bUg16Feuh9CbpSLIyqZuSdbghtzSHKA3iNEQ5LKo0K5Mgci336gHn8/K+7vNL2LpL0tUtqzGtbjhPC1RUaYlzlEcA+kmBAPQd3/QC2/Yty726wUvYvGpjf8zLJvUqln3igk7z2HFC+qZlNWGY9g3pcEPrNEchDCIEYBKFMDDt78fP3dgOdZyz1FzqYT9UhfO6vWFS5bekqNO0iNMCoiyCKIxiEMZBlLxoOt/n8zD2Y1l3pTfdsoZ2RM1SKj7No5z4KIeqKW91UdxylMVuYJv2xbIvjmt9fP5aVm3IlfUCc0XGqa3aVG/9tPRy6TErkzKKcwAzP84CEFshtILwRRA7pvd+PBaDjAkVSGxFN2VUJK1AzRjD3AtiCyATRNcImQG8AmSFyA6REyZ2lLzK+zkbaiXD2IiZDartdDsoMm9dz0tcpyFwPM8EwAmh73mW41q2a1mO6fj2x+fbss7GIOmfdLxlGFJatCRvSE5ZhbIQQMcFtgfswDN933ICy/ZMN7A/L2//P8l/PmPSoUOLm+kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/698edbffb9d55d81474456ecd7981524/e4706/OpenHAB2_02.avif 230w", "/en/static/698edbffb9d55d81474456ecd7981524/d1af7/OpenHAB2_02.avif 460w", "/en/static/698edbffb9d55d81474456ecd7981524/b6582/OpenHAB2_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/698edbffb9d55d81474456ecd7981524/a0b58/OpenHAB2_02.webp 230w", "/en/static/698edbffb9d55d81474456ecd7981524/bc10c/OpenHAB2_02.webp 460w", "/en/static/698edbffb9d55d81474456ecd7981524/87ca7/OpenHAB2_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/698edbffb9d55d81474456ecd7981524/81c8e/OpenHAB2_02.png 230w", "/en/static/698edbffb9d55d81474456ecd7981524/08a84/OpenHAB2_02.png 460w", "/en/static/698edbffb9d55d81474456ecd7981524/e9beb/OpenHAB2_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/698edbffb9d55d81474456ecd7981524/e9beb/OpenHAB2_02.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Execute the following statements to configure openHAB to start automatically using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`systemd`}</code>{`:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` /bin/systemctl daemon-reload
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` /bin/systemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` openhab2.service`}</code></pre></div>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`You can start openhab2 by executing:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` /bin/systemctl start openhab2.service`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/71ad3479804f56c848940959ebb7e748/e9beb/OpenHAB2_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA10lEQVQI1yXO2Y6CMBhAYR5GoIi0QP8uUkpLlFUQis6WzMVEH2De/25iJjn5ro83TN1l7vuxVbb6ttXvqX4cxaMQP4I9C/mU/AOy8oBEjHy0CyM/3Pv/Bmjnbbe5aa2plVTCSJiMulRiUKwvYDZyqvjViPlIDZAcYsYx8JQykubJAUfe59fm3DhOjT2VutZlrdq+Orfa1rJpVNfpYTTacMqwlAnAHjgGQTIgwIjn7tfb+7q40bb2TWd3ileabpAuWbLk2NF0pcRluExQGPvoVfA6j4Mw8v8AKJU3XfSXcMcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71ad3479804f56c848940959ebb7e748/e4706/OpenHAB2_03.avif 230w", "/en/static/71ad3479804f56c848940959ebb7e748/d1af7/OpenHAB2_03.avif 460w", "/en/static/71ad3479804f56c848940959ebb7e748/b6582/OpenHAB2_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/71ad3479804f56c848940959ebb7e748/a0b58/OpenHAB2_03.webp 230w", "/en/static/71ad3479804f56c848940959ebb7e748/bc10c/OpenHAB2_03.webp 460w", "/en/static/71ad3479804f56c848940959ebb7e748/87ca7/OpenHAB2_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71ad3479804f56c848940959ebb7e748/81c8e/OpenHAB2_03.png 230w", "/en/static/71ad3479804f56c848940959ebb7e748/08a84/OpenHAB2_03.png 460w", "/en/static/71ad3479804f56c848940959ebb7e748/e9beb/OpenHAB2_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/71ad3479804f56c848940959ebb7e748/e9beb/OpenHAB2_03.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Open the OpenHAB default port in your Firewall:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` firewall-cmd --permanent --zone`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`public --add-port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`/tcp
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` firewall-cmd --reload
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` firewall-cmd --list-all`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7790526fb73c8744f031cf7ba7333602/e9beb/OpenHAB2_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWUlEQVQY02WP226jMBCGeZX6ODbBYGObOBBMHLKBdntQD08Qqdmu9v2vVxD1qtKn0Ugzn/6Z7O39+f7hNJ7TlPp/sb8O/VeKl253jd2f4zAH+xbb19g+BndrPtJ+DtZ57RuTvX+8zPN4nsdpjH8P8TMNX6d0id2l767DPnitXWVcVXtt1ka7sl7NRX56vj9Px9OvQ9v6tLXRVf1K9ObgTViXmrX+JHt5fRiOe7+zfmu1q5QpyrooayUKEAXkBcicQ84gZ+KGpDdA0iweutA6tzW7zjfBdvsmdN42WulC16r2WpmiqlWlN85VqpSMI8oR4YgwlP1+nKb52AQDklDAPKeYI8wRBcwEBoG5QJRjBhgk4ZIwQRhgwhY/O08pjX1RCsTusMCLvzhISiwFAkD5hsoNY7CMCMfkO3ZJbna1bYwyShtp1R2HxRFyyYH1McipkEQwtPg38/vs//lbX9R06PrrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7790526fb73c8744f031cf7ba7333602/e4706/OpenHAB2_04.avif 230w", "/en/static/7790526fb73c8744f031cf7ba7333602/d1af7/OpenHAB2_04.avif 460w", "/en/static/7790526fb73c8744f031cf7ba7333602/b6582/OpenHAB2_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7790526fb73c8744f031cf7ba7333602/a0b58/OpenHAB2_04.webp 230w", "/en/static/7790526fb73c8744f031cf7ba7333602/bc10c/OpenHAB2_04.webp 460w", "/en/static/7790526fb73c8744f031cf7ba7333602/87ca7/OpenHAB2_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7790526fb73c8744f031cf7ba7333602/81c8e/OpenHAB2_04.png 230w", "/en/static/7790526fb73c8744f031cf7ba7333602/08a84/OpenHAB2_04.png 460w", "/en/static/7790526fb73c8744f031cf7ba7333602/e9beb/OpenHAB2_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7790526fb73c8744f031cf7ba7333602/e9beb/OpenHAB2_04.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Navigate with a web browser to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://<ip-address>:8080`}</code>{` and select the `}<strong parentName="li">{`Standard Package`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b79f2530a094e532faf37615d08b4234/e9beb/OpenHAB2_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACqElEQVQoz2XJXUhTcRjH8WN1oZUvaKVBFpRIRRcSir1oiWGkmFou50QRYzPMpbklUbOaU1NTa0WTtE1tNbepbPgyhZiCYi9qinlhVBdBmts5e3HT7ZzzP/9ztlCorB4+F78vDyLKSr5/mfX+pWy+VzWnezHb0z6nV87plZ8N2q9D3f/4Mtj1zajrl9bdymJJ8tIQDz+WEZymSuNAaTxVngQ4e8lkX5C6nUjZ+kfyX0DqNiY9gE4PQtxGtfu1Cjeq8WEtPqzBhzrcfa14bwsxIHf3y/H+5/iAnDQoyMENDApiHWJ14Ys2p3kVx1YJ84rbtIIvOtxmAJ2M94cTX1pPG0EBr5f8D7JsxcyLC1bUjJlNqGnJgqLYkml5/pPl+wKKojYLZsVQK4raMNRusditVhuGWVHMabfjrlUEUBTj8UCahhBSAECv1+NwWI6eAGPjtNcLAfD+OoZhPB5mYyLv3oy3Nsu0r5T6bu3H6enxmdm+qjpmZ/jbfN7I9MzUxIRGo9HrdJ2dnZOTU2OjYx0dyi6NXqXs/jA1g9SKK5JijnBZKblsVqW4klci3BIQ1n4s4fCeyExucVkxPzQk8MD+fZt8NnO5hZyMEgTxCfLdjSD+/KLriPxJg7CYK5M1C8U1bSq1oLopJC4t/CJvx1lO4b0H4uq66PhEdgE3Mir6mvBmTrYg9lA2J7E0Mvi85EY7MiIVqQvOGBtEbYWZo61ND8vLTu0KunT8ZEJYcE3J1a7aivyDoaJzMbkRgYoKgSIvm+/nXxuRkuMXpb0tQwiDgrqTQTZyPZUsWlMP+p7RNRz6cRFdzQY9UtAjpavYVCOPkmQR+qeEuh5IWMSjK9TdC8RAC+IigBOnHC5yhYA4BUkISUiTFE3SzNqANAEZAv5OZm2sfT0khD8B7hnjxBsrv44AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b79f2530a094e532faf37615d08b4234/e4706/OpenHAB2_09.avif 230w", "/en/static/b79f2530a094e532faf37615d08b4234/d1af7/OpenHAB2_09.avif 460w", "/en/static/b79f2530a094e532faf37615d08b4234/b6582/OpenHAB2_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b79f2530a094e532faf37615d08b4234/a0b58/OpenHAB2_09.webp 230w", "/en/static/b79f2530a094e532faf37615d08b4234/bc10c/OpenHAB2_09.webp 460w", "/en/static/b79f2530a094e532faf37615d08b4234/87ca7/OpenHAB2_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b79f2530a094e532faf37615d08b4234/81c8e/OpenHAB2_09.png 230w", "/en/static/b79f2530a094e532faf37615d08b4234/08a84/OpenHAB2_09.png 460w", "/en/static/b79f2530a094e532faf37615d08b4234/e9beb/OpenHAB2_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b79f2530a094e532faf37615d08b4234/e9beb/OpenHAB2_09.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If the OpenHAB user interface does not load, you are probably missing Java on your Linux server. You can check the OpenHAB service status by typing:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`systemctl status openhab2`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/e9beb/OpenHAB2_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA1klEQVQI1xXK0U6DMBQAUH5mDim05fbetlCkUBiFjS0uPhlfpon/MI0ufrpZch5Pcjgt55fjeozt8HTum895fPXusgsf83iZhsYRWlWIrJCskCwX2R3PeJlnRZpYp+MyrKc4xs7H3s/Bz6GdeqiJG9A1oVGAskQBJIXiSkvlqOpqSSIB5IC86aoQw+F5Py1hXqe4H8POG0emJuMIDZBVFUFrMLSVLYVmeblNk5/b9ffv6/t2fX9blQV1f2AcDsHVTmsrAXkhWUncVqi15DJnjw9su8nSzT9SnDhFwbiVYAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/e4706/OpenHAB2_05.avif 230w", "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/d1af7/OpenHAB2_05.avif 460w", "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/b6582/OpenHAB2_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/a0b58/OpenHAB2_05.webp 230w", "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/bc10c/OpenHAB2_05.webp 460w", "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/87ca7/OpenHAB2_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/81c8e/OpenHAB2_05.png 230w", "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/08a84/OpenHAB2_05.png 460w", "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/e9beb/OpenHAB2_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/664aa6b1a98bcf8a4b449e3a36e1a17f/e9beb/OpenHAB2_05.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "how-to-install-java-with-apt-on-debian-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-install-java-with-apt-on-debian-9",
        "aria-label": "how to install java with apt on debian 9 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to install Java with Apt on Debian 9`}</h3>
    <p>{`In this guide, you will install various versions of the Java Runtime Environment (JRE) and the Java Developer Kit (JDK) using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apt`}</code>{`.`}</p>
    <h4 {...{
      "id": "installing-the-default-jrejdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#installing-the-default-jrejdk",
        "aria-label": "installing the default jrejdk permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing the Default JRE/JDK`}</h4>
    <p>{`The easiest option for installing Java is to use the version packaged with Debian. By default, Debian 9 includes Open JDK, which is an open-source variant of the JRE and JDK.`}</p>
    <p>{`This package will install OpenJDK version 1.8, which is compatible with Java 8. Java 8 is the current Long Term Support version and is still widely supported, though public maintenance ends in January 2019.`}</p>
    <ol>
      <li parentName="ol">{`To install this version, first update the package index:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update`}</code></pre></div>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Next, check if Java is already installed:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`java -version`}</code></pre></div>
    <p>{`If Java is not currently installed, you'll see the following output:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0661b24b1351f980ccf208285f626fbf/e9beb/OpenHAB2_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.086956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOElEQVQI12MI9HfL9ffI9XbL8nJLc7ZKsrN0MjdW1VHX0lbV1FLR0FJR1VBW1VBWUQchVXUlZAQAtF8MDJvNbhgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0661b24b1351f980ccf208285f626fbf/e4706/OpenHAB2_06.avif 230w", "/en/static/0661b24b1351f980ccf208285f626fbf/d1af7/OpenHAB2_06.avif 460w", "/en/static/0661b24b1351f980ccf208285f626fbf/b6582/OpenHAB2_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0661b24b1351f980ccf208285f626fbf/a0b58/OpenHAB2_06.webp 230w", "/en/static/0661b24b1351f980ccf208285f626fbf/bc10c/OpenHAB2_06.webp 460w", "/en/static/0661b24b1351f980ccf208285f626fbf/87ca7/OpenHAB2_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0661b24b1351f980ccf208285f626fbf/81c8e/OpenHAB2_06.png 230w", "/en/static/0661b24b1351f980ccf208285f626fbf/08a84/OpenHAB2_06.png 460w", "/en/static/0661b24b1351f980ccf208285f626fbf/e9beb/OpenHAB2_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0661b24b1351f980ccf208285f626fbf/e9beb/OpenHAB2_06.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Execute the following command to install OpenJDK:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` default-jre`}</code></pre></div>
    <p>{`This command will install the Java Runtime Environment (JRE). This will allow you to run almost all Java software.`}</p>
    <p>{`Verify the installation with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`java -version`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2edb2ba69cbe0563d2819d99eecf9029/e9beb/OpenHAB2_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAb0lEQVQI12MICPL283Pz8XW1c7IJc3GIs7OKtDZ3MdHXNtAyMNLVN9LV0dfUM9DS1lVX11BSVYciFTUQyRAQ7Ofp4+7p7erk6uDpZm5qY6xnZmBobmRqYWRqbmRpZWJspG2or6mnq66hCdYGRhAjAEYnGGlmDWmPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2edb2ba69cbe0563d2819d99eecf9029/e4706/OpenHAB2_07.avif 230w", "/en/static/2edb2ba69cbe0563d2819d99eecf9029/d1af7/OpenHAB2_07.avif 460w", "/en/static/2edb2ba69cbe0563d2819d99eecf9029/b6582/OpenHAB2_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2edb2ba69cbe0563d2819d99eecf9029/a0b58/OpenHAB2_07.webp 230w", "/en/static/2edb2ba69cbe0563d2819d99eecf9029/bc10c/OpenHAB2_07.webp 460w", "/en/static/2edb2ba69cbe0563d2819d99eecf9029/87ca7/OpenHAB2_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2edb2ba69cbe0563d2819d99eecf9029/81c8e/OpenHAB2_07.png 230w", "/en/static/2edb2ba69cbe0563d2819d99eecf9029/08a84/OpenHAB2_07.png 460w", "/en/static/2edb2ba69cbe0563d2819d99eecf9029/e9beb/OpenHAB2_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2edb2ba69cbe0563d2819d99eecf9029/e9beb/OpenHAB2_07.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`You may need the Java Development Kit (JDK) in addition to the JRE in order to compile and run some specific Java-based software. To install the JDK, execute the following command, which will also install the JRE:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` default-jdk`}</code></pre></div>
    <p>{`Verify that the JDK is installed by checking the version of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`javac`}</code>{`, the Java compiler:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`javac -version`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/e9beb/OpenHAB2_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.086956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOklEQVQI12MIDPb18vPwC/CydbLxsdDzNNJxN9Jz0NNW0VBW1VBW11DW1FTS1lTS0lDS0FBWVVdCRgCPPwtWRfPcwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f8ab13e51c95774ae30f43fa5b70d91/e4706/OpenHAB2_08.avif 230w", "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/d1af7/OpenHAB2_08.avif 460w", "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/b6582/OpenHAB2_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f8ab13e51c95774ae30f43fa5b70d91/a0b58/OpenHAB2_08.webp 230w", "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/bc10c/OpenHAB2_08.webp 460w", "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/87ca7/OpenHAB2_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f8ab13e51c95774ae30f43fa5b70d91/81c8e/OpenHAB2_08.png 230w", "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/08a84/OpenHAB2_08.png 460w", "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/e9beb/OpenHAB2_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f8ab13e51c95774ae30f43fa5b70d91/e9beb/OpenHAB2_08.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now navigate with a web browser to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<ip-address>:8080`}</code>{` and select the `}<strong parentName="p">{`Standard Package`}</strong>{` of OpenHAB2:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b79f2530a094e532faf37615d08b4234/e9beb/OpenHAB2_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACqElEQVQoz2XJXUhTcRjH8WN1oZUvaKVBFpRIRRcSir1oiWGkmFou50QRYzPMpbklUbOaU1NTa0WTtE1tNbepbPgyhZiCYi9qinlhVBdBmts5e3HT7ZzzP/9ztlCorB4+F78vDyLKSr5/mfX+pWy+VzWnezHb0z6nV87plZ8N2q9D3f/4Mtj1zajrl9bdymJJ8tIQDz+WEZymSuNAaTxVngQ4e8lkX5C6nUjZ+kfyX0DqNiY9gE4PQtxGtfu1Cjeq8WEtPqzBhzrcfa14bwsxIHf3y/H+5/iAnDQoyMENDApiHWJ14Ys2p3kVx1YJ84rbtIIvOtxmAJ2M94cTX1pPG0EBr5f8D7JsxcyLC1bUjJlNqGnJgqLYkml5/pPl+wKKojYLZsVQK4raMNRusditVhuGWVHMabfjrlUEUBTj8UCahhBSAECv1+NwWI6eAGPjtNcLAfD+OoZhPB5mYyLv3oy3Nsu0r5T6bu3H6enxmdm+qjpmZ/jbfN7I9MzUxIRGo9HrdJ2dnZOTU2OjYx0dyi6NXqXs/jA1g9SKK5JijnBZKblsVqW4klci3BIQ1n4s4fCeyExucVkxPzQk8MD+fZt8NnO5hZyMEgTxCfLdjSD+/KLriPxJg7CYK5M1C8U1bSq1oLopJC4t/CJvx1lO4b0H4uq66PhEdgE3Mir6mvBmTrYg9lA2J7E0Mvi85EY7MiIVqQvOGBtEbYWZo61ND8vLTu0KunT8ZEJYcE3J1a7aivyDoaJzMbkRgYoKgSIvm+/nXxuRkuMXpb0tQwiDgrqTQTZyPZUsWlMP+p7RNRz6cRFdzQY9UtAjpavYVCOPkmQR+qeEuh5IWMSjK9TdC8RAC+IigBOnHC5yhYA4BUkISUiTFE3SzNqANAEZAv5OZm2sfT0khD8B7hnjxBsrv44AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b79f2530a094e532faf37615d08b4234/e4706/OpenHAB2_09.avif 230w", "/en/static/b79f2530a094e532faf37615d08b4234/d1af7/OpenHAB2_09.avif 460w", "/en/static/b79f2530a094e532faf37615d08b4234/b6582/OpenHAB2_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b79f2530a094e532faf37615d08b4234/a0b58/OpenHAB2_09.webp 230w", "/en/static/b79f2530a094e532faf37615d08b4234/bc10c/OpenHAB2_09.webp 460w", "/en/static/b79f2530a094e532faf37615d08b4234/87ca7/OpenHAB2_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b79f2530a094e532faf37615d08b4234/81c8e/OpenHAB2_09.png 230w", "/en/static/b79f2530a094e532faf37615d08b4234/08a84/OpenHAB2_09.png 460w", "/en/static/b79f2530a094e532faf37615d08b4234/e9beb/OpenHAB2_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b79f2530a094e532faf37615d08b4234/e9beb/OpenHAB2_09.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We highly recommend that you read the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/concepts/"
      }}>{`Concepts Introduction`}</a>{`. It introduces a number of important ideas that will help you as you install and begin to configure openHAB for the first time.`}</p>
    <p>{`Once you have openHAB up and running, the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/"
      }}>{`Configuration Guide`}</a>{` contains everything you need to know to get your openHAB installation talking to different devices around your home. For instance, you can use `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/sitemaps.html"
      }}>{`Sitemaps`}</a>{` to control how the status of these devices are displayed on different openHAB User Interfaces, and you can begin to experiment with `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/rules-dsl.html"
      }}>{`Rules`}</a>{` in order to get the most out of your installation. There are many other ways of interacting with your openHAB smart home: the most popular are presented in the Interfaces and Ecosystem section below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2cc3029b3538d3107d0876a7634469d1/e9beb/OpenHAB2_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACCklEQVQoz3XHX2gScQDA8d9D9NJgsV6il4J66zGIgiCCiMaotoeIehksMtxwTrPbH53IXOUeVHKwsfxz08PzmpdMc+r8b26wQVvTmruVixXrDwRNve68u53+woIIoi+fly/QXr9k6Gxfcpg3fGj+qbVhxvbGa9ucdb0LYP/aCmJ2lVx34+rozVZQl52tIRd5xXleeYEfaOVvHePaDlSvNLNtTf8jXGsWO1rEjkPgR/pZJUXSKZJOepkUSUfdpdA0HcGYmJuN49WEh43jv1UTRDWOV+NuNtbAJQhQ2at958SyCFkIy8LeLi8wEPJ1CCGs/fKnGoT1v1aAEBRerfgxNEjgmNXxIp6cCwbHDAaT0TiqH5mPhKPRjN3uwTDSgRIrL3PJaDjgtIQwy3PU/OVDEUT8PqRbMqId7pVKcZers+sOAKCp5TAA+3p6lajdoZAr1INqlVIVDoUtZuNQn1SHyDUK6VtqHVC5XMyJZheW8MTyanGnq28InDh38Ew7OHJSpnlIfdxJrxUW8xuZXOH956+rxe30GpXNb6Zz1LdSBVQy/vKj22Wrdn0M+RT0kP2SwVNH9ZdPq4/vD+hVzJy9PImUpvW7EwizPM+Qj2mbhnbpK1MD7DYFuMUAb+kR0GE43i0GJsTMDJyUQeIBtN6rLfg4r4k33eWf9PMmCZed5Zw6YVzGT91v7Nbrn3ngaX50cohgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2cc3029b3538d3107d0876a7634469d1/e4706/OpenHAB2_10.avif 230w", "/en/static/2cc3029b3538d3107d0876a7634469d1/d1af7/OpenHAB2_10.avif 460w", "/en/static/2cc3029b3538d3107d0876a7634469d1/b6582/OpenHAB2_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2cc3029b3538d3107d0876a7634469d1/a0b58/OpenHAB2_10.webp 230w", "/en/static/2cc3029b3538d3107d0876a7634469d1/bc10c/OpenHAB2_10.webp 460w", "/en/static/2cc3029b3538d3107d0876a7634469d1/87ca7/OpenHAB2_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2cc3029b3538d3107d0876a7634469d1/81c8e/OpenHAB2_10.png 230w", "/en/static/2cc3029b3538d3107d0876a7634469d1/08a84/OpenHAB2_10.png 460w", "/en/static/2cc3029b3538d3107d0876a7634469d1/e9beb/OpenHAB2_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2cc3029b3538d3107d0876a7634469d1/e9beb/OpenHAB2_10.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "openhab-configuration-though-paper-ui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-configuration-though-paper-ui",
        "aria-label": "openhab configuration though paper ui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Configuration though Paper UI`}</h2>
    <p>{`The Paper UI is a new interface that helps setting up and configuring your openHAB instance. It does not (yet) cover all aspects, so you still need to resort to textual configuration files.`}</p>
    <p><strong parentName="p">{`Add-on management`}</strong>{`: Easily install or uninstall `}<a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/%7B%7Bdocu%7D%7D/addons/uis/paper/readme.html"
      }}>{`openHAB add-ons`}</a>{`:`}</p>
    <h3 {...{
      "id": "mqtt-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mqtt-binding",
        "aria-label": "mqtt binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Binding`}</h3>
    <p>{`Go to the `}<strong parentName="p">{`Add-Ons`}</strong>{` Menu and click on the `}<strong parentName="p">{`Bindings Tab`}</strong>{`. As mentioned in the `}<a parentName="p" {...{
        "href": "/en/Software/Linux/OpenHAB2/#installing-mosquitto"
      }}>{`Node-RED Tutorial`}</a>{`, we are going to use the MQTT protocol to communicate with our INSTAR IP camera from OpenHAB. To be able to connect to the `}<a parentName="p" {...{
        "href": "/en/Software/Linux/OpenHAB2/#installing-mosquitto"
      }}>{`Mosquitto MQTT Server`}</a>{` we need to install the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/addons/bindings/mqtt/"
      }}>{`MQTT Binding`}</a>{` - make sure that you choose the `}<strong parentName="p">{`Version 2`}</strong>{` (at the moment of writing this is the `}<em parentName="p">{`2.4.0`}</em>{` version).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/19cfaa8586471a65426cf13255c280b9/e9beb/OpenHAB2_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABRUlEQVQY021OzUoCYRSdZ+iXkFHDRihc9CgaZo6MLSJo06LX6CUKw9RFQS0CrdFdrouWbVxEhA4x48x8P/f7uTGFQtThXjjn3Hu416CVAtvNw14KqibUMsJOQy0r7LRIZBbstLDNGc+IqilsEw62xNE2HBYM4myy4grft6BusdISrZq8uEBq68TZ4KVFVkmRep44Ft9ZZuU1UreIk+PlVebkuJ0xSLch7i9weKMeb5Xbkm5L9BrQb0G/nZCHJvTbMOgItwnuJQw6id89h16D3p0ZClEjjgXGGmNE8i3/QkutlVazqUaUiAZqxbg8deXrezj9nHjjcRyFwCgqqZXUApK90Sg6PmFX11NGaRiikj9lIKLS+u3D8/3A8yae5wVBIKX8fVdrxlEmAIC5nYSllC/PT4HvR1EcJx1zzv/7HRljlNJ5+AvTClDmPYE6ygAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19cfaa8586471a65426cf13255c280b9/e4706/OpenHAB2_11.avif 230w", "/en/static/19cfaa8586471a65426cf13255c280b9/d1af7/OpenHAB2_11.avif 460w", "/en/static/19cfaa8586471a65426cf13255c280b9/b6582/OpenHAB2_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/19cfaa8586471a65426cf13255c280b9/a0b58/OpenHAB2_11.webp 230w", "/en/static/19cfaa8586471a65426cf13255c280b9/bc10c/OpenHAB2_11.webp 460w", "/en/static/19cfaa8586471a65426cf13255c280b9/87ca7/OpenHAB2_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19cfaa8586471a65426cf13255c280b9/81c8e/OpenHAB2_11.png 230w", "/en/static/19cfaa8586471a65426cf13255c280b9/08a84/OpenHAB2_11.png 460w", "/en/static/19cfaa8586471a65426cf13255c280b9/e9beb/OpenHAB2_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/19cfaa8586471a65426cf13255c280b9/e9beb/OpenHAB2_11.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now switch to your OpenHAB Inbox:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a02009dda8769717752ee1575092368d/142fb/OpenHAB2_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABNElEQVQY033Lu04CQRQG4CGQqE+iJsp118sb2ADii0g5jsw5A4vxDSys7UyMtFjQII3xASCasI4IWdyowGqAsGMG1sTCePIX37kRt9uZvLS77Udv6t9fndN1IrYimCI6RijAwkEbwu0l3F3BnWUyfH+beoPh50gpdXd5RleJSIQxSSBBMEYwrqEdnztJIBnCVBjNCBph0uv3R53uV7M1m6l65YLurWE2ynMGzxmQ3uD7CX5g8gMTMlHIRrVzpp5nYoX0JnmQsnddcazTV/l8U6sxDmidQNHSKZUDLFwqY9ESGvrgGAQZfAxGk/HYnymlbuv1fP6wKAQi6AAE+DEAP6KUFwrzIZJms/Vk29K2HcepVquUUqGf/y4AYIxxzhctkVK6rut5nu/7jUaDMfbPMyL+3n4DiqTYzdMWpZIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02009dda8769717752ee1575092368d/e4706/OpenHAB2_12.avif 230w", "/en/static/a02009dda8769717752ee1575092368d/d1af7/OpenHAB2_12.avif 460w", "/en/static/a02009dda8769717752ee1575092368d/81d9e/OpenHAB2_12.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a02009dda8769717752ee1575092368d/a0b58/OpenHAB2_12.webp 230w", "/en/static/a02009dda8769717752ee1575092368d/bc10c/OpenHAB2_12.webp 460w", "/en/static/a02009dda8769717752ee1575092368d/468b0/OpenHAB2_12.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a02009dda8769717752ee1575092368d/81c8e/OpenHAB2_12.png 230w", "/en/static/a02009dda8769717752ee1575092368d/08a84/OpenHAB2_12.png 460w", "/en/static/a02009dda8769717752ee1575092368d/142fb/OpenHAB2_12.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a02009dda8769717752ee1575092368d/142fb/OpenHAB2_12.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`+`}</strong>{` icon to add the MQTT Binding:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a4856821d88e69ca237d2ef0b15100d/a2b88/OpenHAB2_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAhElEQVQI12P4Xyj0r1j8fxojCGUwE0IsIGWFQv8L+P/n8TL8TWL4l8BwqNz/cZbS/3iGP4mMfxMZCKAkKGL4cXXfr5sH+9Y+PXH00v+be75dPfDj2n6C6PvV/T+u7mf4/vvvr///f+/Z/e/Z0///////9+8/XvDv3/8///5//fXnx+9/AHxQpYPzGwBtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a4856821d88e69ca237d2ef0b15100d/e4706/OpenHAB2_13.avif 230w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/d1af7/OpenHAB2_13.avif 460w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/984a9/OpenHAB2_13.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a4856821d88e69ca237d2ef0b15100d/a0b58/OpenHAB2_13.webp 230w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/bc10c/OpenHAB2_13.webp 460w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/1f8a9/OpenHAB2_13.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a4856821d88e69ca237d2ef0b15100d/81c8e/OpenHAB2_13.png 230w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/08a84/OpenHAB2_13.png 460w", "/en/static/4a4856821d88e69ca237d2ef0b15100d/a2b88/OpenHAB2_13.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a4856821d88e69ca237d2ef0b15100d/a2b88/OpenHAB2_13.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Find the MQTT Binding inside the list and select it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "910px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9733c3427224c33c1ad5a8a54245c44f/c6bbc/OpenHAB2_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvklEQVQY043Ly2rCQBTG8bPoUhCKPp1oLys34jVdlPo4xo20j6PmzC4gGI29GGeSMHO+MoPuhR9/vrM4hGFTxi0ZNGT0KJO2jFvoP9yJ0CM8E54IL4TX0O4dOh6Vnx/l6t0sZmYxLeNZ5RsFb7feLH1NHJk48l9fcxLA5r95ejwdztku/8t1fZGqsIELbH0dri6crVBnhT0bAQiAy/ZmvdbMxXajOdGsjGKt2CilmTVzOP0ulXJpapNEfr4B+QfXndIQQ4L5WQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9733c3427224c33c1ad5a8a54245c44f/e4706/OpenHAB2_14.avif 230w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/d1af7/OpenHAB2_14.avif 460w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/f36e1/OpenHAB2_14.avif 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9733c3427224c33c1ad5a8a54245c44f/a0b58/OpenHAB2_14.webp 230w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/bc10c/OpenHAB2_14.webp 460w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/92674/OpenHAB2_14.webp 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9733c3427224c33c1ad5a8a54245c44f/81c8e/OpenHAB2_14.png 230w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/08a84/OpenHAB2_14.png 460w", "/en/static/9733c3427224c33c1ad5a8a54245c44f/c6bbc/OpenHAB2_14.png 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9733c3427224c33c1ad5a8a54245c44f/c6bbc/OpenHAB2_14.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Add Manually`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f5b0ae10d54964519f080d8c33b694f3/a2b88/OpenHAB2_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAyklEQVQY042NsUoDURBFB4XYGLBI/C3BUlcTrBZF0K0s/AdbsyKIVvkHf0IL8wFWYqMgu6NvZo6+bUQixsuZWxy4jHg1jIMVjtb8eODVuh/2vRqy36Ncolz+G2EsTdl/L1cZC3vCqOtdYWcxwvnm2eXt9OKGyUZbF1oXOtlq6+22LhYiwOzZH19D4S1oggT2P4Tw8Iynj4wl3AjPDc3Jqd3dA1jK8if5MxB8J8JVtVVtVJ+url8eZtFZ5iLzKiLMzLtLEeYevy2/xp/QyUmvc0sG0AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f5b0ae10d54964519f080d8c33b694f3/e4706/OpenHAB2_15.avif 230w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/d1af7/OpenHAB2_15.avif 460w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/984a9/OpenHAB2_15.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f5b0ae10d54964519f080d8c33b694f3/a0b58/OpenHAB2_15.webp 230w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/bc10c/OpenHAB2_15.webp 460w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/1f8a9/OpenHAB2_15.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f5b0ae10d54964519f080d8c33b694f3/81c8e/OpenHAB2_15.png 230w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/08a84/OpenHAB2_15.png 460w", "/en/static/f5b0ae10d54964519f080d8c33b694f3/a2b88/OpenHAB2_15.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f5b0ae10d54964519f080d8c33b694f3/a2b88/OpenHAB2_15.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And choose the `}<strong parentName="p">{`MQTT Broker`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "911px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/636c2/OpenHAB2_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqElEQVQoz32SS2/bMBCEdeiph7RIf2ECtOihSJMf2rhNrrFdWw4SBRK8JJePJbW7BeVHU8Dp4sNoDpwdSGIj3z/IzTl/ey/X53LzSa7O+OqsmuuP+vWdfm70y5s05bLhi4Yv98ik48X/4ANNXN6m1Z1fzPxyhvMfOL/1ixmt73NbofU/5PY+re6ONKJ1IJTepd6FbcgQchLNqqRVX5ukKgdUtWEKyiWgRQtoDRrIKRSKhWKumsacdjqZKCXLWJQrDZesqui9MdZaY6wDAEQPBoyBUoqICMtxVHWnU/MujLgFGPoBDBBRjBE9+uBjjCGEGGNMqboQxnE8btmHQ/DoENHF6ai+Pcfkq+YQwFiwBqy1iCnnMLUlotpJFIkS0S7Gh3TDY1ERN5/bxQIeHtJmw89deXo6CXdd5bkTylOYRyWyP3+5roN2k/penBNrT2OMIJbHR+5eDs3MfvnbrdbYtm699u2G+16G4SQ8DGP3IjH9fWfrPTi3tQacA+eo/iLOIoUlM+8ok7IqHy/JmElEg/fWWjcRY8hElNL0vepz71NKMY51LVdE/gCEeeszLZQv1wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd30be70d5805a40bdc65bcf4ba180e1/e4706/OpenHAB2_16.avif 230w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/d1af7/OpenHAB2_16.avif 460w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/e0958/OpenHAB2_16.avif 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd30be70d5805a40bdc65bcf4ba180e1/a0b58/OpenHAB2_16.webp 230w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/bc10c/OpenHAB2_16.webp 460w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/19a92/OpenHAB2_16.webp 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd30be70d5805a40bdc65bcf4ba180e1/81c8e/OpenHAB2_16.png 230w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/08a84/OpenHAB2_16.png 460w", "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/636c2/OpenHAB2_16.png 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd30be70d5805a40bdc65bcf4ba180e1/636c2/OpenHAB2_16.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`With the newest Firmware for your INSTAR Full HD camera, you can use the camera as MQTT Broker. For older cameras models please `}<a parentName="p" {...{
        "href": "/en/Software/Linux/OpenHAB2/#installing-mosquitto"
      }}>{`install a MQTT broker like Mosquito first`}</a>{`. Add your brokers IP address and the broker port - by default this is `}<strong parentName="p">{`1883`}</strong>{`. Note that we are first not going to use `}<a parentName="p" {...{
        "href": "https://community.openhab.org/t/mqtt-binding-and-ssl/40622"
      }}>{`TLS encryption`}</a>{`. We will add it once we are able to establish the connection:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3411de74fb822cdb1082145b38f3f997/4ef49/OpenHAB2_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACV0lEQVQ4y52Ty27TQBiFI9FFWyGBxJ6nAwQtpUuWPACvwROwR4gdVdULpYDUhCa22zhOnMR3e8Yz86Gx0/SiIrW19GlsyXN0zn/psPUYtp/A1iPYWsdsP4XNNXjRgZd3xP77qgObq3T06xWSt8/49OE9o3fPYWMFubmOfLN6fzbW6Kizn4TH+3z8PMfZ3UMdfKH8s4PwjhHOEcK9O5VzRAdA+2NU4CHSjCLOUcMJD30aQek4xLM5QkqMqlGOg9EaoxRG349GUI0C8m6PcuCgXA/ljzDQihpzZ1qH9sOK2su3RLi/4MKJjSvrmloppKWuEUI8TFApRTg8Z+x5DROL75NmGVrr/zbgppg9W8EwJO39JRk4SFu/cIryzkCIi5uNcF3XzWkN3CZ+6fDsnNjziMYTyihCFAXSddFx3NbVGMqyZDabkaUpaZoibYkWqFpdd2iKAjWeNE7r8QQZjFHh9LoDDDa8Nu2pjFmib0a2MebDIZGNmxeYPG/QcYKOIoyNbmOWFVQVpiwXtO/IepmkjTydEv36jZxMYDpDh9MGM5ujRwF64JCfdJn1ehR2ToMAHYxbJiHytN+kvKxhOCUKAnKlKGqJMKYFqISgdj1Et0d22ifv95Fn52h/hB76zVJUJz1MUV4K6tmc5Os38t09yt09xP4h0nL4A/F9B9XtLTfnakcvJuBqp1vBOCE+6VLbQa4q9AW2g3ECw3P8uMIdZ1RFTmZrvBil5RwuNq4VTBKm+wdEjksyGJA4LtXQp/RHFP0BteMilCJNErIsW1JVVbNNdnQuHP4DJW4514Alp4IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3411de74fb822cdb1082145b38f3f997/e4706/OpenHAB2_17.avif 230w", "/en/static/3411de74fb822cdb1082145b38f3f997/d1af7/OpenHAB2_17.avif 460w", "/en/static/3411de74fb822cdb1082145b38f3f997/ada94/OpenHAB2_17.avif 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3411de74fb822cdb1082145b38f3f997/a0b58/OpenHAB2_17.webp 230w", "/en/static/3411de74fb822cdb1082145b38f3f997/bc10c/OpenHAB2_17.webp 460w", "/en/static/3411de74fb822cdb1082145b38f3f997/8d1ba/OpenHAB2_17.webp 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3411de74fb822cdb1082145b38f3f997/81c8e/OpenHAB2_17.png 230w", "/en/static/3411de74fb822cdb1082145b38f3f997/08a84/OpenHAB2_17.png 460w", "/en/static/3411de74fb822cdb1082145b38f3f997/4ef49/OpenHAB2_17.png 890w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3411de74fb822cdb1082145b38f3f997/4ef49/OpenHAB2_17.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your INSTAR Full HD cameras MQTT default login is the Administrator login you added to your camera (if you are using Mosquitto, use the password you defined when `}<a parentName="p" {...{
        "href": "/en/Software/Linux/OpenHAB2/#configuring-mosquitto"
      }}>{`setting up your server`}</a>{`). Then save your setting by clicking on the blue confirmation button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "892px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aeaad3fcd1e29b062145058c0da923ce/1568e/OpenHAB2_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACXElEQVQ4y2WTzW7TQBDHo8IFwQ3EG/BUfEkICSREUasiUNsTF8QrcEVwoMnLcOBDpaWN6pSgJrZje73erx/Mxk7dstIvszs7O/uf9WQQNm4SNm/Bi+vI3G/eJjy/AU/WCE+vEp5egdb2Ed/Sf5XwZA2eXYsMuD9APxpwsn6H8GBAeDhAfNw9J4i916P1hX5MuzdguMWX0XvWhzPmozfovVdUe9tUw5a9berRLtmnl8w+bJJ+3EINlz5BDXcoP7+mHu2gR7sMAOrjPxw93iAvHYWsL+FabIu/hOvNY0J/9Av99h2hqSG4//EW19RYrfBG400Dfulf4UwkJnQhkDYNqq6pW5qmQWsdCSFQFAVpmpLneZyLX2IE50QjMW6p0HtUWVIrFZMZY6KvQwK75N1FYvv7FxJaa0nn82i7DaE7IEOUNcbQH2H1283ahCJZyqiqirIs41wpRdMqkVBJmGVZLFtsXOeyztDaXlQYgo+lSil9hf2hqoqqLOPl1hictRFrxC5VypHVG0pCUSVldwkjbcmNc9TW0ngf5x2+V35PYYi3dYdXb9SpNAZ7kuCSCW4ywSZJRNZemE5Xsas3PJvPWciXlq8nqtuA2NSzGcnXA6ZpTb4w5IWNLApLVjqq41NQKqpclmwtOs1Q8zSisxydZbiyxKsav1hQHR5RjBOqcUIzmaKT3+hkac04IbQdsmybk4Tm9BSyDLIU5nNIWzub4SenuEZjygKnKpxS8Rki0gWXG1v/2Gf65Rtn3w/Ifx5THJ6cczDG7B9S1VLugqIsKauKRqqSf0q/d/8p/Av595MtnpD02gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aeaad3fcd1e29b062145058c0da923ce/e4706/OpenHAB2_18.avif 230w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/d1af7/OpenHAB2_18.avif 460w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/33335/OpenHAB2_18.avif 892w"],
              "sizes": "(max-width: 892px) 100vw, 892px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aeaad3fcd1e29b062145058c0da923ce/a0b58/OpenHAB2_18.webp 230w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/bc10c/OpenHAB2_18.webp 460w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/7aa27/OpenHAB2_18.webp 892w"],
              "sizes": "(max-width: 892px) 100vw, 892px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aeaad3fcd1e29b062145058c0da923ce/81c8e/OpenHAB2_18.png 230w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/08a84/OpenHAB2_18.png 460w", "/en/static/aeaad3fcd1e29b062145058c0da923ce/1568e/OpenHAB2_18.png 892w"],
              "sizes": "(max-width: 892px) 100vw, 892px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aeaad3fcd1e29b062145058c0da923ce/1568e/OpenHAB2_18.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the `}<strong parentName="p">{`Things Menu`}</strong>{` click on `}<strong parentName="p">{`+`}</strong>{` again - this time to add a `}<strong parentName="p">{`Thing`}</strong>{` that we can use for an `}<em parentName="p">{`PaperUI`}</em>{` button later on:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "905px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/762f00b484b4448131e4574aea7cc40c/65d79/OpenHAB2_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABAUlEQVQY052OvUoDURBGrxZpBFELG1/KF1BTKRIwiqVPIT6KiNkgBAQLsYoptFlWlkTj38bd7N27P/feI5tEjanEgcMw8x2YEdSXsIersL8AtQqmvow9WIHtOdiZ/xulu1uBvUWEqQrMpuDqaJ3H2hpsCHS52/oHVYFI2w2SuxbHJ9fcnF7AvUPSbpJ2mqjbEmfSp+hMz86U4yASDTnA+Rm4Hl9l+V12htks1qAAYXUBusB/eeU9/OCp69PrdXnrP+P3H7j0Wqh0CMZQuiPfaDKVoGSMHEZEgwDsOBfWju95rssgCAjDcISUCVmeEaUR1pqfbyZ+kRdkWYZSKTKW39knpNWOxrwjQc0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/762f00b484b4448131e4574aea7cc40c/e4706/OpenHAB2_19.avif 230w", "/en/static/762f00b484b4448131e4574aea7cc40c/d1af7/OpenHAB2_19.avif 460w", "/en/static/762f00b484b4448131e4574aea7cc40c/8df31/OpenHAB2_19.avif 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/762f00b484b4448131e4574aea7cc40c/a0b58/OpenHAB2_19.webp 230w", "/en/static/762f00b484b4448131e4574aea7cc40c/bc10c/OpenHAB2_19.webp 460w", "/en/static/762f00b484b4448131e4574aea7cc40c/4d060/OpenHAB2_19.webp 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/762f00b484b4448131e4574aea7cc40c/81c8e/OpenHAB2_19.png 230w", "/en/static/762f00b484b4448131e4574aea7cc40c/08a84/OpenHAB2_19.png 460w", "/en/static/762f00b484b4448131e4574aea7cc40c/65d79/OpenHAB2_19.png 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/762f00b484b4448131e4574aea7cc40c/65d79/OpenHAB2_19.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`MQTT Binding`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3137a78829b88f39eafb5b9f701fc4c9/142fb/OpenHAB2_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAo0lEQVQY043GMQrCMAAF0KCrkxa8nIhuXQRLVRyc9CZacHDrlWwSqFYENQna/i+mOIlQeMMTnHYRBZh0EPUQ9xEFDFsM200IDgXGgiPB8dewgcGHcOna7pdmG5vdzNaSuU0W3m/8d3N7WLl0I0C+bq6w1fXJk4EpUZKv/0CWQHl3IAWJKs9NdnwoecuORkmrldHSah+l6vhLpzUuRXU+4VKQeANncNSe7QyBUQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3137a78829b88f39eafb5b9f701fc4c9/e4706/OpenHAB2_20.avif 230w", "/en/static/3137a78829b88f39eafb5b9f701fc4c9/d1af7/OpenHAB2_20.avif 460w", "/en/static/3137a78829b88f39eafb5b9f701fc4c9/81d9e/OpenHAB2_20.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3137a78829b88f39eafb5b9f701fc4c9/a0b58/OpenHAB2_20.webp 230w", "/en/static/3137a78829b88f39eafb5b9f701fc4c9/bc10c/OpenHAB2_20.webp 460w", "/en/static/3137a78829b88f39eafb5b9f701fc4c9/468b0/OpenHAB2_20.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3137a78829b88f39eafb5b9f701fc4c9/81c8e/OpenHAB2_20.png 230w", "/en/static/3137a78829b88f39eafb5b9f701fc4c9/08a84/OpenHAB2_20.png 460w", "/en/static/3137a78829b88f39eafb5b9f701fc4c9/142fb/OpenHAB2_20.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3137a78829b88f39eafb5b9f701fc4c9/142fb/OpenHAB2_20.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And `}<strong parentName="p">{`Add Manually`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "906px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf9f87c29b3e98e20ac89512b55ee552/6029f/OpenHAB2_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA4UlEQVQY04XLwUoDMRgE4B8UT14EWxAfypMnxfagUHDdYtuX8VjSLRQKvoRXfQS9CuKCaJNukvlHstKlsAWHj+FnSASjDm8PdHSMyQnGXQyPMO5ysM/B3r+EPfnMTqubQ/aEfeFV3ZcbF1t3i3ybfGLenswDi2tbDNdF7maZm90lJlsv7t08d2azNIpEQL6WKJ2uyBVplSAjGTW1//gKrgKJem/EmlAjqYoYfYXgETwRqUhN/pyd+6khSV+lpaGJMH3ldgBY65y1zvv35WP5/KLtR3WkPalq/AtiUA2awl35BaLSSvq2Ud/0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf9f87c29b3e98e20ac89512b55ee552/e4706/OpenHAB2_21.avif 230w", "/en/static/cf9f87c29b3e98e20ac89512b55ee552/d1af7/OpenHAB2_21.avif 460w", "/en/static/cf9f87c29b3e98e20ac89512b55ee552/f5e2f/OpenHAB2_21.avif 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf9f87c29b3e98e20ac89512b55ee552/a0b58/OpenHAB2_21.webp 230w", "/en/static/cf9f87c29b3e98e20ac89512b55ee552/bc10c/OpenHAB2_21.webp 460w", "/en/static/cf9f87c29b3e98e20ac89512b55ee552/45005/OpenHAB2_21.webp 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf9f87c29b3e98e20ac89512b55ee552/81c8e/OpenHAB2_21.png 230w", "/en/static/cf9f87c29b3e98e20ac89512b55ee552/08a84/OpenHAB2_21.png 460w", "/en/static/cf9f87c29b3e98e20ac89512b55ee552/6029f/OpenHAB2_21.png 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf9f87c29b3e98e20ac89512b55ee552/6029f/OpenHAB2_21.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This time we need to add a `}<strong parentName="p">{`Generic MQTT Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/a2b88/OpenHAB2_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.521739130434778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvklEQVQY033KwQoBURgF4MvCUiEe0Ciy0OCJZJStlYxSdjb2rEiZmTC4zdTcO+b/j4wUivo6nU5HwCxwt8KtPHcq3C2zWSCz+FjaJTRzqGfQyP4iEkMkNUE1QcYrjXSs/pUehF7N1XIcjnvhdBBM+qFthbalZsMv0VuJbEstRno1FwBYBpeTPMfsR7G80TUmDcSfbikNMEBK08EHIADiyzlYr+VuJ7cbud0o19Geq71nvvOU6yT+MXH25LkA3wFGINqXYffGhQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62ea91dc10d4dbfa7d9e071e8724372b/e4706/OpenHAB2_22.avif 230w", "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/d1af7/OpenHAB2_22.avif 460w", "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/984a9/OpenHAB2_22.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62ea91dc10d4dbfa7d9e071e8724372b/a0b58/OpenHAB2_22.webp 230w", "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/bc10c/OpenHAB2_22.webp 460w", "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/1f8a9/OpenHAB2_22.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62ea91dc10d4dbfa7d9e071e8724372b/81c8e/OpenHAB2_22.png 230w", "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/08a84/OpenHAB2_22.png 460w", "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/a2b88/OpenHAB2_22.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62ea91dc10d4dbfa7d9e071e8724372b/a2b88/OpenHAB2_22.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am going to create a button that triggers the alarm on my INSTAR Full HD camera and name it here accordingly. As `}<strong parentName="p">{`Bridge`}</strong>{` you need to select the MQTT broker you just created. Click on the blue button to confirm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/52912be6535ec52463714848dc46243c/a2b88/OpenHAB2_23.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABUklEQVQoz5WQzUpCQRTHh6IggiiC6m16ibblooW4CJNw2bJdD9AbtMioh4j8yHTdh9uuZnbLazofd+YXer2SINI9w48zc+D853+OILuOO9mG7Ark1rD5LdzxBi63CZklSC9AenGc55BZhqNVhE0JXEpQz+/SPNyBPUGYEgzrdj8BBxFCPhRQ95ec3XjcXVzD+Sn92i2yfIWsFP7NYIwAcNLRbzRQXpPQDxjVEhKHGD7tR5tuu80gCLCehxseo3GhSczIof3u0n95pff0TPjmRb9ai3MuESOH8SVUGmfMxHpSsYlg3Cy1ZqAUA6lQw3Fn7WquYLT5SNBaer5P8Pk5yv3uN2gNWkUoBUYzL6Yc2tY7fqnKR6VOp1KjU67RqdR5L1Zplx5pFav45RryK0Aah5QGKcMIFdL7kVhr/4xsDLrbQ80gruvgB6NDjLFT6HGOHf4Cki4WEgH97Y0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52912be6535ec52463714848dc46243c/e4706/OpenHAB2_23.avif 230w", "/en/static/52912be6535ec52463714848dc46243c/d1af7/OpenHAB2_23.avif 460w", "/en/static/52912be6535ec52463714848dc46243c/984a9/OpenHAB2_23.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/52912be6535ec52463714848dc46243c/a0b58/OpenHAB2_23.webp 230w", "/en/static/52912be6535ec52463714848dc46243c/bc10c/OpenHAB2_23.webp 460w", "/en/static/52912be6535ec52463714848dc46243c/1f8a9/OpenHAB2_23.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/52912be6535ec52463714848dc46243c/81c8e/OpenHAB2_23.png 230w", "/en/static/52912be6535ec52463714848dc46243c/08a84/OpenHAB2_23.png 460w", "/en/static/52912be6535ec52463714848dc46243c/a2b88/OpenHAB2_23.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/52912be6535ec52463714848dc46243c/a2b88/OpenHAB2_23.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the `}<strong parentName="p">{`Thing Menu`}</strong>{` click to edit the `}<strong parentName="p">{`Thing`}</strong>{` you just created:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "906px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d5b78358176ecaa069a31203ff948f5d/6029f/OpenHAB2_24.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABPElEQVQoz42QvUoDQRSFR1SwsEirlU/gE5lYGhH8weAr+AYWgpva3sJ2Yx2CVm6CmuAmrrqzE3dndrI/c49kExU1oB+Hy+XA4XAvo9oqDkvYX6a9EtVWsL2I6sIf2prHzhL2Swxldru73q+uYYNRZQ5l9i9VGDYZM1enxxfe+eUNGieRXY9tK7YtaZ+phhU36rNlW3q8WCwBUgMi/Ia+69PMgTDHiMBgsqEQUfgWCi74qwj8Ifev3aYruiCiPEWeIUtNlqQjnWiVaBVHIUxGecoAtDsd1+3zIPA8T0VxqrKe6PHYL3qmlUQ0KtBah2E4ccZhJaXWmge+4OLu+b7ltX4cQZHMH7rGmPyDiT8OO47juq7nPQ36g/Zjx3lxDJlppzEAsmYrOjhSvh8niZSSc/7VbAqomDCEWc+byTsj88H5oAPGygAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5b78358176ecaa069a31203ff948f5d/e4706/OpenHAB2_24.avif 230w", "/en/static/d5b78358176ecaa069a31203ff948f5d/d1af7/OpenHAB2_24.avif 460w", "/en/static/d5b78358176ecaa069a31203ff948f5d/f5e2f/OpenHAB2_24.avif 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d5b78358176ecaa069a31203ff948f5d/a0b58/OpenHAB2_24.webp 230w", "/en/static/d5b78358176ecaa069a31203ff948f5d/bc10c/OpenHAB2_24.webp 460w", "/en/static/d5b78358176ecaa069a31203ff948f5d/45005/OpenHAB2_24.webp 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5b78358176ecaa069a31203ff948f5d/81c8e/OpenHAB2_24.png 230w", "/en/static/d5b78358176ecaa069a31203ff948f5d/08a84/OpenHAB2_24.png 460w", "/en/static/d5b78358176ecaa069a31203ff948f5d/6029f/OpenHAB2_24.png 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d5b78358176ecaa069a31203ff948f5d/6029f/OpenHAB2_24.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the blue `}<strong parentName="p">{`+`}</strong>{` button to add a `}<strong parentName="p">{`Channel`}</strong>{` to the `}<strong parentName="p">{`Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/794b2fcca560a311642e26f4ea6277cf/142fb/OpenHAB2_25.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABGElEQVQoz42PzUoDMRSFA+rCgtWiC5c+llJbXIlC6Yy6ULBVfBS3glNfwRdo9wUX/oFotTNJTDKT5B5p6EJhkPn4NucezuIyRKt0vIF4hTo1HzUobvhunU42KV7H4RIOFv66iH2GaA1RHZ0awy5Dm711t8zeMpqMWvMLWgw7ZW6Hts3QZKwY9LLbq/Ob11FyjbszlVyapG+Snkn6enBRbtLTs7bPCCDgeaom2mYEG2JFGbwjZ7+lyNIvkU5dkXtbeJuXWYQ2h7PwDt4xAN57LoSRhqeZlDI3Rv/ChGgCSimttfcegdkYhLzIx5MxNzwkQjXmYyHF8GX4zj+4hn18IqWrjokoTTPF5edU3D8U/OjUDUcI//w//gHMG8I0nkgVowAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/794b2fcca560a311642e26f4ea6277cf/e4706/OpenHAB2_25.avif 230w", "/en/static/794b2fcca560a311642e26f4ea6277cf/d1af7/OpenHAB2_25.avif 460w", "/en/static/794b2fcca560a311642e26f4ea6277cf/81d9e/OpenHAB2_25.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/794b2fcca560a311642e26f4ea6277cf/a0b58/OpenHAB2_25.webp 230w", "/en/static/794b2fcca560a311642e26f4ea6277cf/bc10c/OpenHAB2_25.webp 460w", "/en/static/794b2fcca560a311642e26f4ea6277cf/468b0/OpenHAB2_25.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/794b2fcca560a311642e26f4ea6277cf/81c8e/OpenHAB2_25.png 230w", "/en/static/794b2fcca560a311642e26f4ea6277cf/08a84/OpenHAB2_25.png 460w", "/en/static/794b2fcca560a311642e26f4ea6277cf/142fb/OpenHAB2_25.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/794b2fcca560a311642e26f4ea6277cf/142fb/OpenHAB2_25.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am going to use an `}<strong parentName="p">{`On/Off Switch`}</strong>{` which is not ideal for the function - the Alarm-Trigger command does not have an On/Off state. Unfortunately OpenHab does not offer a simple button. The MQTT topic to trigger the alert is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/pushalarm`}</code>{` and as message payload we need to add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`. Click on `}<strong parentName="p">{`Save`}</strong>{` to confirm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5eb26ba17532140af0487afe44643e82/4255a/OpenHAB2_26.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA1UlEQVQoz5VR6Q6CMBjb+z+d/sADEBATQJhT2JUwthq8ggQJfknTZEfbLyVcKVSMgTUNzpSCMYapcc7Nnvfcg4i8QBmEuCZH1HmOumkgOIcQAlLKB48/DTE2I3K1xsUPoKIYnbdFf2Wt/cKvdEOx91ui0xOEtWidQxclcC+BIcYGU4bGmKdgn+zGOUzbojvEk2stwWdlFSdQL3VziJ6uC1KNE76ZSD+AkBKac3R+OFvAEpA2CKF2e0hvA5OeJkv5JykRWqMoS7C6RlFVyLIMlNLZZufmDjlAwh/dAnHHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eb26ba17532140af0487afe44643e82/e4706/OpenHAB2_26.avif 230w", "/en/static/5eb26ba17532140af0487afe44643e82/d1af7/OpenHAB2_26.avif 460w", "/en/static/5eb26ba17532140af0487afe44643e82/6e770/OpenHAB2_26.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5eb26ba17532140af0487afe44643e82/a0b58/OpenHAB2_26.webp 230w", "/en/static/5eb26ba17532140af0487afe44643e82/bc10c/OpenHAB2_26.webp 460w", "/en/static/5eb26ba17532140af0487afe44643e82/632b0/OpenHAB2_26.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eb26ba17532140af0487afe44643e82/81c8e/OpenHAB2_26.png 230w", "/en/static/5eb26ba17532140af0487afe44643e82/08a84/OpenHAB2_26.png 460w", "/en/static/5eb26ba17532140af0487afe44643e82/4255a/OpenHAB2_26.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5eb26ba17532140af0487afe44643e82/4255a/OpenHAB2_26.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e5b3307e77cc98815b92d3c177e0a56/4255a/OpenHAB2_26a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIElEQVQoz5WS2YrDMAxF8/+f2afSQuN61WZ5iG9rUpiB6XkQIbIt61hbzvl2u12v1xDC4/G43+8xxvE/NiJKKdVae+9mpqruvtLMXEoRkd83mxkziwgzExEiEWFDrTWEECcpJRFRVUR335g559xaW9uAmY0xVJWZzaxP8GGTo/K+75fLhYjG92yqSkSoPMbwE+i51vpnzxCWc44x4v7z1kd0d2QX6HyurK+eU0qoLCIQbqZm3vtgplpra00m/Eaku8/K+76XUpbzt/bDQs75+XymlIhIPzkqt9aQxmvzCXcXEfTMzL13dz/HV88whF9nYWUCPVgAXsJaaycNB6UUKHR3DAmyIYT8Bv633jt6WOedMTPM0xqMj6f6djBijEvqDwoDNOF76zk7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e5b3307e77cc98815b92d3c177e0a56/e4706/OpenHAB2_26a.avif 230w", "/en/static/0e5b3307e77cc98815b92d3c177e0a56/d1af7/OpenHAB2_26a.avif 460w", "/en/static/0e5b3307e77cc98815b92d3c177e0a56/6e770/OpenHAB2_26a.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e5b3307e77cc98815b92d3c177e0a56/a0b58/OpenHAB2_26a.webp 230w", "/en/static/0e5b3307e77cc98815b92d3c177e0a56/bc10c/OpenHAB2_26a.webp 460w", "/en/static/0e5b3307e77cc98815b92d3c177e0a56/632b0/OpenHAB2_26a.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e5b3307e77cc98815b92d3c177e0a56/81c8e/OpenHAB2_26a.png 230w", "/en/static/0e5b3307e77cc98815b92d3c177e0a56/08a84/OpenHAB2_26a.png 460w", "/en/static/0e5b3307e77cc98815b92d3c177e0a56/4255a/OpenHAB2_26a.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e5b3307e77cc98815b92d3c177e0a56/4255a/OpenHAB2_26a.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By clicking on the blue icon in front of the created channel we are now able to link it to a switch in the `}<strong parentName="p">{`OpenHAB PaperUI`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "904px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/d9217/OpenHAB2_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABWklEQVQoz5WOTStEYRTHHy+FUt5SPovvwpgkihix8iXEWFlZsbCzM7csrNgiiY2GZpoMc+/z3Huft/PCvcOEJJ5+nc556vc/R/DKMK2Nc2mQl/ppdYyWh2hlmNYnqDTKC7083/2VHp4TXBrJWBwQVBBmtu96bVIX+6kgcCanmNfpn5gSWOzKKAgBwWb1eG/jSFaDfT7ZSoMdHZR1sJ2z8zOVbV0p66AsmBmI6s0wMl56Jv7HE4yA3iklo7AloxCc/Qx6l/Peg7PeGQTPCIyQb0aIVWwTIyOZpKm1VmttjOnUrLGm/a+1RqSPzcxEpFJ11biKbZyNf75dZC6gjMPL2kUrUc20nUZ/lBkRXiKlWrL+FJ3eaSb43elEC1999DPFg92zw1uWT7WH6n2z+WyMTpLEOffZcdYpKcMwBHhPF5RqPj+/uW7cPRN6i9nJBACI+O14IkKAjvkmvwKczDiXyAoXTAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc8a9cfad9e7a9612ef268c2ca427022/e4706/OpenHAB2_27.avif 230w", "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/d1af7/OpenHAB2_27.avif 460w", "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/c9047/OpenHAB2_27.avif 904w"],
              "sizes": "(max-width: 904px) 100vw, 904px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc8a9cfad9e7a9612ef268c2ca427022/a0b58/OpenHAB2_27.webp 230w", "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/bc10c/OpenHAB2_27.webp 460w", "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/82aba/OpenHAB2_27.webp 904w"],
              "sizes": "(max-width: 904px) 100vw, 904px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc8a9cfad9e7a9612ef268c2ca427022/81c8e/OpenHAB2_27.png 230w", "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/08a84/OpenHAB2_27.png 460w", "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/d9217/OpenHAB2_27.png 904w"],
              "sizes": "(max-width: 904px) 100vw, 904px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc8a9cfad9e7a9612ef268c2ca427022/d9217/OpenHAB2_27.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Create new item...`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/de30e6cbb332e6fa9acd0780d8c28620/142fb/OpenHAB2_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQklEQVQoz32Ry07CUBCGeTs3Jj6AC1/BrTHR2FohPafNuc0pF0No6kKkZeuKsJCYuDAlPgUUSSC0oUjDMQIq0eq3mczim/kzUwAAIYSGNA1rZUdKCZxzjLG1A+cc8igAAGPMMI0iNhCRmHDXbYRh+PxJv9/3PI8x9jH4t0wIMREhlnWiW+eXqNW8XSwWSZLEcZymqVKq3W4TQnLksgMGhoNjKCEbm8WirnuuuzGTJMmyTCkVBIFt24wxhBClVAixlSUA5XBhcsoYF2BWq427Zpqm8/n8bc3u5i/tO7YEqDgAUjpC3B8ePV2VXmezaDgcjUbT6XS5XAZBkB97U4QAIWWZ88e9/ZfTs8FkEg0GURSNx+M4jn3f/0/e4jhmtXLjt5RSWZatViu15s+D/egFpde1Wq/X63Q63W73YU29Xs999TsOFWE5gs79ngAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de30e6cbb332e6fa9acd0780d8c28620/e4706/OpenHAB2_28.avif 230w", "/en/static/de30e6cbb332e6fa9acd0780d8c28620/d1af7/OpenHAB2_28.avif 460w", "/en/static/de30e6cbb332e6fa9acd0780d8c28620/81d9e/OpenHAB2_28.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/de30e6cbb332e6fa9acd0780d8c28620/a0b58/OpenHAB2_28.webp 230w", "/en/static/de30e6cbb332e6fa9acd0780d8c28620/bc10c/OpenHAB2_28.webp 460w", "/en/static/de30e6cbb332e6fa9acd0780d8c28620/468b0/OpenHAB2_28.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de30e6cbb332e6fa9acd0780d8c28620/81c8e/OpenHAB2_28.png 230w", "/en/static/de30e6cbb332e6fa9acd0780d8c28620/08a84/OpenHAB2_28.png 460w", "/en/static/de30e6cbb332e6fa9acd0780d8c28620/142fb/OpenHAB2_28.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/de30e6cbb332e6fa9acd0780d8c28620/142fb/OpenHAB2_28.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Link`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "705px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c78cbddffc35551c9f62c2251daf62c/d2cbc/OpenHAB2_29.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIklEQVQ4y6WRS1KFMBBFsytnrsWJ23Hg3HJlOngFOICENCG/7nRiAVpaJbwHeIohN336tkgpAYAxRmsNAMxcdiOcc0opAJBSjuOYcz4Q9hPOe4+I+2OzYBZSmU6athtkP4IJZsTR0s1vMIE5C++DtdY5Nyv4lNIB7RBC3/daa+dcKSXvAxFzziL/A7HUe6jkH21EJKJyiik8DINzzs4cuzMz4zdE9Hex5b/1nZn5+rQ0s3kqAPDebz0RZja1iSjGuCp2xXkKnzvS1+QY41bJv4dvnsoYs1oJzaznci4pndVedl7aijEeirFU9PY+aXvvrbVExHtAzKXYp+f+7l4opaqqquu6aZqPWzRNU10uda+6l1d4eBSHNo0xtm3LzBFRG/MJZ8QPSDwCPFQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c78cbddffc35551c9f62c2251daf62c/e4706/OpenHAB2_29.avif 230w", "/en/static/9c78cbddffc35551c9f62c2251daf62c/d1af7/OpenHAB2_29.avif 460w", "/en/static/9c78cbddffc35551c9f62c2251daf62c/98a83/OpenHAB2_29.avif 705w"],
              "sizes": "(max-width: 705px) 100vw, 705px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c78cbddffc35551c9f62c2251daf62c/a0b58/OpenHAB2_29.webp 230w", "/en/static/9c78cbddffc35551c9f62c2251daf62c/bc10c/OpenHAB2_29.webp 460w", "/en/static/9c78cbddffc35551c9f62c2251daf62c/af945/OpenHAB2_29.webp 705w"],
              "sizes": "(max-width: 705px) 100vw, 705px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c78cbddffc35551c9f62c2251daf62c/81c8e/OpenHAB2_29.png 230w", "/en/static/9c78cbddffc35551c9f62c2251daf62c/08a84/OpenHAB2_29.png 460w", "/en/static/9c78cbddffc35551c9f62c2251daf62c/d2cbc/OpenHAB2_29.png 705w"],
              "sizes": "(max-width: 705px) 100vw, 705px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c78cbddffc35551c9f62c2251daf62c/d2cbc/OpenHAB2_29.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now click on `}<strong parentName="p">{`Control`}</strong>{` to open the `}<strong parentName="p">{`PaperUI`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "909px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92ced4c12d91534b02d5e3c113ca4c50/b3ad9/OpenHAB2_30.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOElEQVQoz2P48PLZnyd3Xrx8+vX///ObF1boMtTbCNRb8dabs9ebMNabMKEjY8Z6K556c7Z6M1aG+3fvPnv54sXyFZ9nLzowvblKg6HBgKlen6FeDzcyYAAp0GdgePL48btPnz4ePf77wOFDG1eWRbrUxHtXx3nhRZ4QBsObN2++fPny+devP///Hz99pqqhuaGts74VjjrACM4GM1o6IIjhzp3b9+7de/zw4euXL/fs3l1RXlZXW1NbU40V1VRX1dZU19fVQhDD9+/fv3379u/fv////58+fbqsrKy2tq4GBmpra+EsuEg9DDD8/PXrx48fEM3nzp6tqqpqaGioRwXofLjmx48fP370+MWLFx8+vN+1d395ZVVjAy7FGJo/f/785cuXHz9+/P//f+u+EzlFFc1NxGoGAFZpIuFTCW8RAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92ced4c12d91534b02d5e3c113ca4c50/e4706/OpenHAB2_30.avif 230w", "/en/static/92ced4c12d91534b02d5e3c113ca4c50/d1af7/OpenHAB2_30.avif 460w", "/en/static/92ced4c12d91534b02d5e3c113ca4c50/cc01b/OpenHAB2_30.avif 909w"],
              "sizes": "(max-width: 909px) 100vw, 909px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92ced4c12d91534b02d5e3c113ca4c50/a0b58/OpenHAB2_30.webp 230w", "/en/static/92ced4c12d91534b02d5e3c113ca4c50/bc10c/OpenHAB2_30.webp 460w", "/en/static/92ced4c12d91534b02d5e3c113ca4c50/ed09a/OpenHAB2_30.webp 909w"],
              "sizes": "(max-width: 909px) 100vw, 909px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92ced4c12d91534b02d5e3c113ca4c50/81c8e/OpenHAB2_30.png 230w", "/en/static/92ced4c12d91534b02d5e3c113ca4c50/08a84/OpenHAB2_30.png 460w", "/en/static/92ced4c12d91534b02d5e3c113ca4c50/b3ad9/OpenHAB2_30.png 909w"],
              "sizes": "(max-width: 909px) 100vw, 909px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92ced4c12d91534b02d5e3c113ca4c50/b3ad9/OpenHAB2_30.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our Alarm Trigger now appears in the `}<strong parentName="p">{`Paper UI`}</strong>{` and clicking the switch will send the MQTT message to our MQTT server that is going to notify our camera to trigger an alert. Note the MQTT.fx program at the bottom of the frame `}<a parentName="p" {...{
        "href": "/en/Software/Linux/OpenHAB2/#testing-mosquitto-from-an-external-machine"
      }}>{`can be used to verify`}</a>{` that the command was actually send - but the installation `}<strong parentName="p">{`is optional`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5fea16514e6325fd96cf6091367105d/142fb/OpenHAB2_31.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADGUlEQVQ4y4WRSW/bRhiGxymQQy9Fb/0NFskhZ0htlIZD0qTWKnElyy0axUsax5ZlSbYTZHGQIggQ5JBLnQYFiv6OXpScih4K9JbElmQDAXpxiwJOmrhStJBTUKqX2IX74MGA8w2+jy9mAKt+7Kx+4pY+ZFfOsPkP3vPKGTY3crw4rBfPsspHgF0cWADswvt+CdjsCPtqxPu4cMICYFMAdGrrndo3vaffdp88OmrnyaPu0+86tcfd2vqxo3+trYMuY33GWox1GHOO2B9U3p0q2H3z588vf3qzu9vae+v2e26/5/R7rNfZaTs7Dx//dXGm9fpVu9Vq771t/713TNDptX9v7TDGmOuyA1znHWPtH2vd739g/b7rOt5cp38gcxzmOuDZL7/Wn280G42tZrNZ39xqbDYbm8365nb9xfZvL7f+2Gk26tuN+nazceBWo17feLHx7DmwU58Zlm3RKCURjVJKdU2jhFJipWnsnG3ZiUSSEBL12F8JiRIaS+mA6FaImCS7oCYmw6FQOByOkqiqRsJED0W8WYZhhILBgN8f8Pv9AQ8ZiwFZNiwVjKfjE18UJkt3c7OVXDaby2Yn8vkxy5KDEVEJcT7O5/PxHAdFJMkBSRRFUQoaKT+xBEEC+cl8Op3KpGKZdDKTyXx6frxy6dKDcmXt8uKN1VtL1Wq5XC4vLVWWV1eu3VhZri4vr9y89/D61/dvrd0G5pgJIYwnkrquRwmNjM9+niusTU3dmZ5enbu86DUOKJVKi8XSgOL8XKk4f/XaVRAOKBjLGGN5CEYSkjie53jex3mZD9nfchzPcfzo6ChQgwGEED5EVhRlOEf5P0BQkSGE0hEwRvIgzNBTALZpaJQOX1IbEFJViLEoy9JAdEpzKhGLJxKEEFVVbdumumFq1BAEA4oax+scHzy8j0MwxqIoerEFCJEkIYSQJPEQ5sas6tR0cWamMJGfTqVNWRZP5FcUZWFhAahBP/JG4+EPRITOUz0Zj9OYHbWsmKbpCEknAiuKYprmoHn/tmWMRYTSupGWpDEBGoKQ5AVN+o9mhBCE8B8DzwXNXwQK9gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5fea16514e6325fd96cf6091367105d/e4706/OpenHAB2_31.avif 230w", "/en/static/c5fea16514e6325fd96cf6091367105d/d1af7/OpenHAB2_31.avif 460w", "/en/static/c5fea16514e6325fd96cf6091367105d/81d9e/OpenHAB2_31.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5fea16514e6325fd96cf6091367105d/a0b58/OpenHAB2_31.webp 230w", "/en/static/c5fea16514e6325fd96cf6091367105d/bc10c/OpenHAB2_31.webp 460w", "/en/static/c5fea16514e6325fd96cf6091367105d/468b0/OpenHAB2_31.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5fea16514e6325fd96cf6091367105d/81c8e/OpenHAB2_31.png 230w", "/en/static/c5fea16514e6325fd96cf6091367105d/08a84/OpenHAB2_31.png 460w", "/en/static/c5fea16514e6325fd96cf6091367105d/142fb/OpenHAB2_31.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5fea16514e6325fd96cf6091367105d/142fb/OpenHAB2_31.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Instead of using `}<a parentName="p" {...{
        "href": "/en/Software/Linux/OpenHAB2/#testing-mosquitto-from-an-external-machine"
      }}>{`MQTT.fx`}</a>{` you can also just check your cameras log book that should now show an `}<strong parentName="p">{`Audio Alarm`}</strong>{` (the manual alarm trigger uses the audio alarm to trigger all your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`programmed Alarm Actions`}</a>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dece9684480753594a40d38c3a71b854/a2b88/OpenHAB2_32.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABNElEQVQY02MwMbcwMbc0Nbc0s7SxsnWwsnO0tLHXNzZDICNTfWMzAxNzPSNTU0sbM0trTV19YzMLY1MzBl1dXQMDAxkZmbbW5hfPH9+5df3RgztPHz94+ug+iHz84NmTh08e3Hl49+a9W9fevny2dPEiYWFhKSkpeXl5Bh0dHV1dPWVF2aisljlrb09ecn7ysotTl1+atvzylGUXpi270LXiZvX6pzVrH1etetCx421W42xZWWllVWUlFSWQZh1dPS01BbPAyqDywz4lu7yLdnsX7/Iu3eNVuNO/aIdzyX79+kvG9Rf1qs8HTH2YUDNTRlZSRVFWX18XollfW1XOOLrVuvexVcslq9Yrli2XrNquWjZfsm657Dbpocekh279d5x67yQsfp3dtlhQgEdaXFhXWx0A4c2ASf1vJQcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dece9684480753594a40d38c3a71b854/e4706/OpenHAB2_32.avif 230w", "/en/static/dece9684480753594a40d38c3a71b854/d1af7/OpenHAB2_32.avif 460w", "/en/static/dece9684480753594a40d38c3a71b854/984a9/OpenHAB2_32.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dece9684480753594a40d38c3a71b854/a0b58/OpenHAB2_32.webp 230w", "/en/static/dece9684480753594a40d38c3a71b854/bc10c/OpenHAB2_32.webp 460w", "/en/static/dece9684480753594a40d38c3a71b854/1f8a9/OpenHAB2_32.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dece9684480753594a40d38c3a71b854/81c8e/OpenHAB2_32.png 230w", "/en/static/dece9684480753594a40d38c3a71b854/08a84/OpenHAB2_32.png 460w", "/en/static/dece9684480753594a40d38c3a71b854/a2b88/OpenHAB2_32.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dece9684480753594a40d38c3a71b854/a2b88/OpenHAB2_32.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All right that's it - the Binding was successfully added and we are now able to control all of our camera functions with the MQTT interface.`}</p>
    <h3 {...{
      "id": "openhab-cloud-connector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#openhab-cloud-connector",
        "aria-label": "openhab cloud connector permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Cloud Connector`}</h3>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#access-your-smarthome-remotely"
      }}>{`OpenHAB Cloud`}</a>{` to access your Smarthome over the internet. First we need to install `}<strong parentName="p">{`openHAB Cloud Connector`}</strong>{` that can be found in the `}<strong parentName="p">{`Add-ons Menu`}</strong>{` in the `}<strong parentName="p">{`Miscellaneous`}</strong>{` section:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "906px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6e017abcba8afed05f6f7c588aba05b9/6029f/OpenHAB2_33.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABB0lEQVQY022Ny0oDQRBFOyPGx0J0I/pzvsnDhYroFwiu3bv1AxQhEeMua1GSiZuI7iQPx/icSaa7+kh3BINYcKi6ty5Vyu7MIbvz2K0ZyGUgH0A+80Mw0v/6Y7/z9izsL2L3FlB2TWEKWeySQheymFWF3RgiK4p0cxpZV9hlhS5OoItTGLf3+XF0cdLvxXm5ABVfn9G/LSH1MtKowEMVOT1GnxzBYxUJK5j6BaZWwoSX6MaIvrvCeF32un9zjkoBDcQW+kBvYOm9J7w6Ukgsvuw/iIAwxGl3SyHGW42wRrv1RNTt0Gm3iJ67vL1EDJIYl7Gisa7r1D8wzSbxwSEf900+ky8Q7XPfAc9Bc6syOqYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e017abcba8afed05f6f7c588aba05b9/e4706/OpenHAB2_33.avif 230w", "/en/static/6e017abcba8afed05f6f7c588aba05b9/d1af7/OpenHAB2_33.avif 460w", "/en/static/6e017abcba8afed05f6f7c588aba05b9/f5e2f/OpenHAB2_33.avif 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6e017abcba8afed05f6f7c588aba05b9/a0b58/OpenHAB2_33.webp 230w", "/en/static/6e017abcba8afed05f6f7c588aba05b9/bc10c/OpenHAB2_33.webp 460w", "/en/static/6e017abcba8afed05f6f7c588aba05b9/45005/OpenHAB2_33.webp 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6e017abcba8afed05f6f7c588aba05b9/81c8e/OpenHAB2_33.png 230w", "/en/static/6e017abcba8afed05f6f7c588aba05b9/08a84/OpenHAB2_33.png 460w", "/en/static/6e017abcba8afed05f6f7c588aba05b9/6029f/OpenHAB2_33.png 906w"],
              "sizes": "(max-width: 906px) 100vw, 906px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6e017abcba8afed05f6f7c588aba05b9/6029f/OpenHAB2_33.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you need to create a free account on the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#access-your-smarthome-remotely"
      }}>{`OpenHAB Cloud Service`}</a>{`. Once your account is set up and you are signed in, go to the `}<a parentName="p" {...{
        "href": "https://myopenhab.org/account"
      }}>{`Account Page`}</a>{`. Here you need to add your `}<strong parentName="p">{`OpenHAB UUID`}</strong>{` and `}<strong parentName="p">{`OpenHAB Secret`}</strong>{`. Those can be found on your OpenHAB installation under the following paths (make sure that you install the `}<strong parentName="p">{`Cloud Connector`}</strong>{` first for them to be generated):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` /var/lib/openhab2/uuid
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` /var/lib/openhab2/openhabcloud/secret`}</code></pre></div>
    <p>{`Add your UUID and Secret on your `}<a parentName="p" {...{
        "href": "https://myopenhab.org/account"
      }}>{`Account Page`}</a>{` and update your configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "909px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/42e64aad65835af6401f6fb752cd99b8/b3ad9/OpenHAB2_35.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB10lEQVQoz3WOz04TURTGr0sTnkI3xmXVFT4GyAqDkaWpbHUhCxoSeQET0J2G9mEMIUAMUjoFOm1nhnb+3Xvn3HPP3KPTooRav/xyc/Kd++U7gicKB8Go59PAD/vBiedrMMzs+I4y4zJ0xIzM5cQRrlnn1ob8sq731szuS7X3Kv38mr69cc36DNza4NZb16yX+zeO4GXh1u6Xm4/p/UN696D88MitL/CS4JV7vCLu8GLyrgpYErRczcJt1VzjKX9crNhZ5J3nvP2MG7X/sv2EG7UqtVUTttCFVpdR7IVxNxp7QcXFderH0o9lP7ll6vhx3hvnuZQWtLDWFgb7seqNZS+WQQ7DDILcRAojhcOsGKQVf51IYShRGWutFYgIBuJkFKdJKnNiVzITOyJbkpXJ6DocxuMxFJrIksUpUwkkgiiSxyfq7Nx0uqbjVZy1IcsMkdZKSmmMQYsGb/kTthaiKD04TI+Oi9OfVfLcM+2OyXMkUkplWaa1xnkSyGySxHw/gMMj+HGKzFiW6Bza6jgAKIqiap4blp925df9ztVV1O/bokBjbphIazUtl1LOCYPXhYvLtPqjLdHMWimZJAkAzC0X1YXOEZG1dmZHREEYttttAPh3+zv8C25jo24kt0DQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/42e64aad65835af6401f6fb752cd99b8/e4706/OpenHAB2_35.avif 230w", "/en/static/42e64aad65835af6401f6fb752cd99b8/d1af7/OpenHAB2_35.avif 460w", "/en/static/42e64aad65835af6401f6fb752cd99b8/cc01b/OpenHAB2_35.avif 909w"],
              "sizes": "(max-width: 909px) 100vw, 909px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/42e64aad65835af6401f6fb752cd99b8/a0b58/OpenHAB2_35.webp 230w", "/en/static/42e64aad65835af6401f6fb752cd99b8/bc10c/OpenHAB2_35.webp 460w", "/en/static/42e64aad65835af6401f6fb752cd99b8/ed09a/OpenHAB2_35.webp 909w"],
              "sizes": "(max-width: 909px) 100vw, 909px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/42e64aad65835af6401f6fb752cd99b8/81c8e/OpenHAB2_35.png 230w", "/en/static/42e64aad65835af6401f6fb752cd99b8/08a84/OpenHAB2_35.png 460w", "/en/static/42e64aad65835af6401f6fb752cd99b8/b3ad9/OpenHAB2_35.png 909w"],
              "sizes": "(max-width: 909px) 100vw, 909px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/42e64aad65835af6401f6fb752cd99b8/b3ad9/OpenHAB2_35.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now back to the `}<strong parentName="p">{`Paper UI`}</strong>{` go to `}<strong parentName="p">{`Configuration`}</strong>{` and `}<strong parentName="p">{`Services`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f9b0823d620b303409418a3cd4e83fb/024d6/OpenHAB2_36.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABSElEQVQoz3WNy07CQBSGx7jCeAkSdeUzmGhMUECWPooLL0lNqbSdM0NJ9LGMG+MjgGi5JFwGLWkpQ50iMCZe0ET651v8yZ/vHDQKQ6/VCNp1xrp+r3uTWYUdRPcW6C6KZH+RJmM0tYI8z+N8OJlMpJTcfb0+3oZ0nB4u4+wGZNZJMgZHCZzd/O6ZBGS3ILVGDpYgHUdBEAgh5HQqpRxyTq4U9ewE1HOSu5iLqZzOVvRULj9VKrVardPp2LZdsIq6YdKCRa3iXDAQ8rMiIaU/GLwFwXg8dl3X0PVLRdG0HGCTEvIfbBoE4Ksjcf/gNJtuvy+EcBzHsizDMAghNCKmac5WxG/vXuqNnusOfJ8xhjHOfUbX9bny39PoXUrO+SgMpZS+71NKNU0DgKjPAPArP5ZKtv3cbrcYY9VqFWOsqmqUTAjJ5/Mz+QPuDy0RlECadAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f9b0823d620b303409418a3cd4e83fb/e4706/OpenHAB2_36.avif 230w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/d1af7/OpenHAB2_36.avif 460w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/7f308/OpenHAB2_36.avif 920w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/7733b/OpenHAB2_36.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f9b0823d620b303409418a3cd4e83fb/a0b58/OpenHAB2_36.webp 230w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/bc10c/OpenHAB2_36.webp 460w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/966d8/OpenHAB2_36.webp 920w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/85db8/OpenHAB2_36.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f9b0823d620b303409418a3cd4e83fb/81c8e/OpenHAB2_36.png 230w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/08a84/OpenHAB2_36.png 460w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/c0255/OpenHAB2_36.png 920w", "/en/static/2f9b0823d620b303409418a3cd4e83fb/024d6/OpenHAB2_36.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f9b0823d620b303409418a3cd4e83fb/c0255/OpenHAB2_36.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can find the `}<strong parentName="p">{`Cloud Connector`}</strong>{` in the `}<strong parentName="p">{`IO Section`}</strong>{`. Click to configure it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b86b92b28e629f499b63d91ab18b7819/339e3/OpenHAB2_37.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3klEQVQY04WLy0rDQBSGZyO4KIxiBal9O3VhwYRU4tsIvoH4CEXEvRgqKEWM1aSIIDSTc2bORZJWFCn041v9F6MnHYm3JNmWwabEVo439MDoodGj9RqObEj7lPY4sjzc5bTHZ/s87HJkKe3T6V6Tx5aSLiU7HNvlvs0N5hnkGeYZvT344pFmkzCb+OIpTMf4cg/Pd9i2f4Wfi0ESJKk9vb6X06IsPz5Fl5Aqii4GKzUINULtARo9eARhCgiOgru8mp9foCq6ajH7p8EWaJ6/uKr6cm5+c+tG16iCALiKb8m0HYhHlYffAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b86b92b28e629f499b63d91ab18b7819/e4706/OpenHAB2_37.avif 230w", "/en/static/b86b92b28e629f499b63d91ab18b7819/d1af7/OpenHAB2_37.avif 460w", "/en/static/b86b92b28e629f499b63d91ab18b7819/7f308/OpenHAB2_37.avif 920w", "/en/static/b86b92b28e629f499b63d91ab18b7819/5d0a8/OpenHAB2_37.avif 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b86b92b28e629f499b63d91ab18b7819/a0b58/OpenHAB2_37.webp 230w", "/en/static/b86b92b28e629f499b63d91ab18b7819/bc10c/OpenHAB2_37.webp 460w", "/en/static/b86b92b28e629f499b63d91ab18b7819/966d8/OpenHAB2_37.webp 920w", "/en/static/b86b92b28e629f499b63d91ab18b7819/56bc3/OpenHAB2_37.webp 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b86b92b28e629f499b63d91ab18b7819/81c8e/OpenHAB2_37.png 230w", "/en/static/b86b92b28e629f499b63d91ab18b7819/08a84/OpenHAB2_37.png 460w", "/en/static/b86b92b28e629f499b63d91ab18b7819/c0255/OpenHAB2_37.png 920w", "/en/static/b86b92b28e629f499b63d91ab18b7819/339e3/OpenHAB2_37.png 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b86b92b28e629f499b63d91ab18b7819/c0255/OpenHAB2_37.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And make sure that the `}<strong parentName="p">{`Remote Access`}</strong>{` is active and the `}<strong parentName="p">{`Base URL`}</strong>{` is set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://myopenhab.org/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/550dbec6bf5a870969d26801cc81d922/e548f/OpenHAB2_38.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIUlEQVQoz4XPXW+CMBiGYf//AbRFmdGf5JGKLiay0VKmBwYpLfSDtiwD3bJEt+v07Z0nnSCEAABJkhBK37J3TEiGcZZlx+MxTVOMMaW0KAo6yPO8KIo0TefzOQBgghAKw5DmuRKiLq8tqwVjbdsKIaSUQojmTimltbbWMsYWi8U9hhCvVk2y48mO7/ZivXFS+r73zo29UkpK2XWdc67ve875LY4QCsLwA2PPuS5LXV715WKU0sY45/TAWtsNjDHe+594XCbbpN2/VtukWm+MlP0T3vtfy18xAPnhoM/nmhYcEyWE8zf/xFEUBUFwOp2+b3/MPloOQ5xlNWNVVTlr3SPjV5umKcuSELJcLm8xhDCO45dB/Nz4YDabTadTCCFC6BOCFMcOaVPqbgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/550dbec6bf5a870969d26801cc81d922/e4706/OpenHAB2_38.avif 230w", "/en/static/550dbec6bf5a870969d26801cc81d922/d1af7/OpenHAB2_38.avif 460w", "/en/static/550dbec6bf5a870969d26801cc81d922/7f308/OpenHAB2_38.avif 920w", "/en/static/550dbec6bf5a870969d26801cc81d922/6d24a/OpenHAB2_38.avif 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/550dbec6bf5a870969d26801cc81d922/a0b58/OpenHAB2_38.webp 230w", "/en/static/550dbec6bf5a870969d26801cc81d922/bc10c/OpenHAB2_38.webp 460w", "/en/static/550dbec6bf5a870969d26801cc81d922/966d8/OpenHAB2_38.webp 920w", "/en/static/550dbec6bf5a870969d26801cc81d922/1e975/OpenHAB2_38.webp 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/550dbec6bf5a870969d26801cc81d922/81c8e/OpenHAB2_38.png 230w", "/en/static/550dbec6bf5a870969d26801cc81d922/08a84/OpenHAB2_38.png 460w", "/en/static/550dbec6bf5a870969d26801cc81d922/c0255/OpenHAB2_38.png 920w", "/en/static/550dbec6bf5a870969d26801cc81d922/e548f/OpenHAB2_38.png 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/550dbec6bf5a870969d26801cc81d922/c0255/OpenHAB2_38.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now go back to your terminal and reboot your Linux system that is running OpenHab with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo reboot`}</code>{`. Once OpenHAB is back up refresh the `}<a parentName="p" {...{
        "href": "https://myopenhab.org/events?source=openhab"
      }}>{`Cloud Page`}</a>{` - you should see that your status is now set to `}<strong parentName="p">{`ONLINE`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/04d0722c8c10faa3f5898bedc8fca73f/e548f/OpenHAB2_39.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABrElEQVQoz2XRT28SQRzG8XkPvggPxlQ06c2TiSdvRmuxFZTaRhAqRqMHjYGY6MHExItFXooeCt6MlwZIW8UYbfgjaFlY2N3Zndmv2QEl1Uk+mef35DenEZncHTK3c2Q386RzeW5lN1lPZ9nI5EhtpLl2Y43VZIrLyyvEV5NmjiwlrhK/niCZWieRukn+3gPu3n+IaO18QDd36O7XqdVreM4ECAm1wnUmBL7EHlrsNhp02i2csY3jTmi2PtKzvmFOqAlVYN4I+eIcwfOzqOIpgsKCuXUxdkTUu49PIJ+cRBdi6GcLDC4uMl5bNFkVT6OeniEoxBDBo+Oo/DGIC7gyFf7D9MtT4ZIwu8F5gbow6+MCvSJwLwmEevsS9e4VwfaWoSolwmoZVXmD2o6UjtDVMrpSRr9/japuTbPZjXIJ4QFyxgfGwC+pceA/LnAoFSM9zRErAEvNZ2FbA3zXQUYf4LlYox5fu3UO+nu0Dz/RsZpTg31zt3/U6PQadIZf6FpNDvq7HPzco2N9pj/8jrBtGynlX77vz8w7x5ngTUZ4noeUvulc18Pz5vt/dn8DlTEF/5W7J+8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04d0722c8c10faa3f5898bedc8fca73f/e4706/OpenHAB2_39.avif 230w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/d1af7/OpenHAB2_39.avif 460w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/7f308/OpenHAB2_39.avif 920w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/6d24a/OpenHAB2_39.avif 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/04d0722c8c10faa3f5898bedc8fca73f/a0b58/OpenHAB2_39.webp 230w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/bc10c/OpenHAB2_39.webp 460w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/966d8/OpenHAB2_39.webp 920w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/1e975/OpenHAB2_39.webp 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04d0722c8c10faa3f5898bedc8fca73f/81c8e/OpenHAB2_39.png 230w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/08a84/OpenHAB2_39.png 460w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/c0255/OpenHAB2_39.png 920w", "/en/static/04d0722c8c10faa3f5898bedc8fca73f/e548f/OpenHAB2_39.png 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/04d0722c8c10faa3f5898bedc8fca73f/c0255/OpenHAB2_39.png",
              "alt": "OpenHAB 2 with your INSTAR IP Camera",
              "title": "OpenHAB 2 with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When you now switch to the `}<a parentName="p" {...{
        "href": "https://home.myopenhab.org/start/index"
      }}>{`dashboard URL`}</a>{` you will be able to see your regular OpenHAB interface. And this URL will work from anywhere where you have internet access:`}</p>
    <h2 {...{
      "id": "openhab-2-with-your-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-2-with-your-instar-ip-camera",
        "aria-label": "openhab 2 with your instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><span parentName="h2" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.47826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA2ElEQVQY033KPWoCURiF4c9BTS3uQYJooyKIEotESWcjSSfoqDOCWGQPwXQWWZE/A9mBf60LsDDXe+Z65wtRUBxlXp7uHGq+5gcvme/nzKiaG1Wyw3J6+JT6Kqc/S8lGIVEvJN5uvBcf+7WiWc0SBjHXiuom6dY/z7xQJqnOPW2CFXHtB2IrxF1i61qXuBfmjzjbhn86sQ22DVJrR60cdzn1W82w/sFiisXkjvkY8zFtJXauVh772isNj8FByNk4UuwByeydaX0Q4heQkEFISIHjC9fJYwjsD+QSK/MBqO6yAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/74060ea5fea8852506b50d589db23bec/e4706/OpenHAB2_40.avif 230w", "/en/static/74060ea5fea8852506b50d589db23bec/d1af7/OpenHAB2_40.avif 460w", "/en/static/74060ea5fea8852506b50d589db23bec/7f308/OpenHAB2_40.avif 920w", "/en/static/74060ea5fea8852506b50d589db23bec/b84c9/OpenHAB2_40.avif 980w"],
            "sizes": "(max-width: 920px) 100vw, 920px",
            "type": "image/avif"
          }}></source><source parentName="picture" {...{
            "srcSet": ["/en/static/74060ea5fea8852506b50d589db23bec/a0b58/OpenHAB2_40.webp 230w", "/en/static/74060ea5fea8852506b50d589db23bec/bc10c/OpenHAB2_40.webp 460w", "/en/static/74060ea5fea8852506b50d589db23bec/966d8/OpenHAB2_40.webp 920w", "/en/static/74060ea5fea8852506b50d589db23bec/58b01/OpenHAB2_40.webp 980w"],
            "sizes": "(max-width: 920px) 100vw, 920px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/en/static/74060ea5fea8852506b50d589db23bec/81c8e/OpenHAB2_40.png 230w", "/en/static/74060ea5fea8852506b50d589db23bec/08a84/OpenHAB2_40.png 460w", "/en/static/74060ea5fea8852506b50d589db23bec/c0255/OpenHAB2_40.png 920w", "/en/static/74060ea5fea8852506b50d589db23bec/d30ee/OpenHAB2_40.png 980w"],
            "sizes": "(max-width: 920px) 100vw, 920px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/en/static/74060ea5fea8852506b50d589db23bec/c0255/OpenHAB2_40.png",
            "alt": "OpenHAB 2 with your INSTAR IP Camera",
            "title": "OpenHAB 2 with your INSTAR IP Camera",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      